import React, { useContext  } from 'react'
import { useSelector, useDispatch } from 'react-redux';


const CartButton = ({props}) => {
const product = props;

  const cart = useSelector(state => state.cart);
  const dispatch = useDispatch();

  const addToCart = item => {
    dispatch({ type: 'ADD_TO_CART', item });
  };
  return (
        <div style={{paddingTop: 10}}>
            <a onClick={() => addToCart(product)}>
                <p >Add to Cart</p>
            </a>
        </div>
  )
}

export default CartButton
