import React, { useState } from "react";

const VariantDropdown = ({ variants, handleSelect }) => {
  const [selectedOption, setSelectedOption] = useState(variants.variant[0]);

  const handleChange = (event) => {
    const selectedIndex = event.target.selectedIndex;
    const selectedValue = event.target.value;
    const selectedVariant = variants.variant[selectedIndex];
    const newSelectedOption = {
      variant_title: variants.variant_title,
      variant_name: selectedValue,
      // You can include any other properties from the selected variant object
      // For example: variant_price: selectedVariant.variant_price
    };
    setSelectedOption(newSelectedOption);
    handleSelect(newSelectedOption);
  };

  return (
    <div>
      <label htmlFor="variants">{variants.variant_title} : </label>
      <select id="variants" onChange={handleChange} value={selectedOption.variant_name}>
        {variants.variant.map((variant, index) => (
          <option key={index} value={variant.variant_name}>
            {variant.variant_name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default VariantDropdown;
