import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import SEO from "../components/seo"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import redirectToCheckout from '../components/redirectToCheckout';
import CartButton from "../components/cartbutton";
import VariantDropdown from "../components/VariantDropdown";

export const ProductPostTemplate = ({
  id,
  name,
  description,
  unit_amount,
  featuredimage,
  variants,
  content, 
  contentComponent,
  images
}) => {
  const PageContent = contentComponent || Content;
  const [currentSlide, setCurrentSlide] = useState(0);
  const [VariantSected, setVariantSected] = useState(handledefult_select(variants));
  const [VariantSectedString, setVariantSectedString] = useState(handledefult_select2(variants));

  useEffect(() => {
  }, [VariantSected]);
  useEffect(() => {
  }, [VariantSectedString]);

  function handledefult_select(vars) {
    // console.log(vars);
    var output = [];
    if(vars ){
      for (let i = 0; i < vars.length; i++) {
        var new_var = { [vars[i].variant_title]: vars[i].variant[0].variant_name };
        output.push(new_var);
      }
    }else{
      output = ' '
    }
    // console.log(output)
    return output;
  }
  

  function handledefult_select2(vars) {
    if (!vars) {
      return ' ';
    }
  
    var output = [];
    for (let i = 0; i < vars.length; i++) {
      var variantTitle = vars[i].variant_title;
      var variantName = vars[i].variant[0].variant_name;
      output.push(`${variantTitle}: ${variantName}`);
    }
  
    return output.join(', ');
  }
  


  function handleVariantChange(variant) {
    setVariantSected((prevVariantSelected) => {
      const updatedVariantList = prevVariantSelected.map((item) => {
        const key = Object.keys(item)[0];
        if (key === variant.variant_title) {
          return { [key]: variant.variant_name };
        }
        return item;
      });
  
      const string = updatedVariantList
        .map((item) => `${Object.keys(item)[0]}: ${Object.values(item)[0]}`)
        .join(", ");
  
      setVariantSectedString(string);
      return updatedVariantList;
    });
  }
  

  const handleSelect = (selectedOption) => {
    // Do something with the selected option
    handleVariantChange(selectedOption);

    console.log("Selected option:", selectedOption);


  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1, // set slidesToScroll to 1 to scroll one image at a time
    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlide(newIndex);
    },
  };
  

  return (
<div>
<section className="section">
  <div className="container content">
    <h2>{name}</h2>
    <div className="columns is-vcentered" style={{paddingTop: 40, paddingBottom:75}}>
      <div style={{padding: 40}}>
          <div style={{ maxWidth: '400px', margin: '0 auto' }}>
            <Slider {...settings}>
              <PreviewCompatibleImage imageInfo={{ image: featuredimage }} />
              {images.map((image, index) => (
                <PreviewCompatibleImage key={index} imageInfo={{ image: image.image }} />
              ))}
            </Slider>
          </div>
      </div>
        <div style={{padding: 40}}>
          <p>{description}</p>
          <div>
            <h2>£{unit_amount /100}</h2>
          </div>
          <div>
            {variants ? (
          variants.map((variant, index) => (
            <VariantDropdown
              key={index}
              variants={variant}
              handleSelect={handleSelect}
            />
          ))        ) : null}
          </div>
          <div style={{paddingTop: 20}}>
            <a href="#" onClick={(e) => redirectToCheckout.redirectToCheckout(e, {name : name, unit_amount:  unit_amount, description:  VariantSectedString})}>
              <p >Buy Now</p>
            </a>
          </div>
          <CartButton props={{name : name, unit_amount:  unit_amount, description:  VariantSectedString, id : id, image: featuredimage }} />
        </div>
    </div>
  </div>
  <PageContent className="content" content={content} />

</section>
</div>

  );
};


ProductPostTemplate.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  unit_amount: PropTypes.number,
  featuredimage : PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  variants: PropTypes.object,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  images: PropTypes.arrayOf(PropTypes.object),
};




const ProductPost = ({ data }) => {
  const { markdownRemark: post, allStripePrice: products } = data;
  return (
    <Layout featuredimage={post.frontmatter.featuredimage}>
    <SEO title={post.frontmatter.meta.title} description={post.frontmatter.meta.description} />
      {ProductPostTemplate({
        id: post.frontmatter.id,
        name : post.frontmatter.name,
        featuredimage : post.frontmatter.featuredimage,
        // stripePrice: stripePrice,
        contentComponent: HTMLContent,
        content: post.html,
        description: post.frontmatter.description,
        variants: post.frontmatter.variants,
        unit_amount : post.frontmatter.unit_amount,
        images: post.frontmatter.images // Pass the images array to the template

      })}

    </Layout>
  );
};


ProductPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default ProductPost;

export const pageQuery = graphql`
query ProductPostByID($id: String!) {
  markdownRemark(id: { eq: $id }) {
    id
    html
    frontmatter {
      name
      id
      title
      unit_amount
      featuredimage {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
          )

        }
      }
      description
      variants {
        variant_title
        variant{
          variant_name
        }
      }
      images {
        image {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED  width: 600 height: 400)
          }
        }
      }
      id
      meta {
        title
        description
      }
    }
  }

}
`;

